import { ChannelProvider } from "../../models/ProvidersModel";
import { getData } from "./fetch";

export function loadProviders(
  setProviders: (providers: ChannelProvider[]) => void
) {
  getData(`/providers/list`).then((providersList: ChannelProvider[]) => {
    if (providersList.length) {
      setProviders(providersList);
    }
  });
}
