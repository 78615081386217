import React, { useContext } from "react";
import { AppContext } from "../../contexts/AppContext";
import { MoonIcon, SunIcon } from "@heroicons/react/24/outline";
import { Button } from "flowbite-react";

export default function DarkModeToggleButton() {
  const { isDarkMode, setIsDarkMode } = useContext(AppContext);

  return (
    <Button
      className="border-none focus:ring-0"
      color="light"
      onClick={() => setIsDarkMode(!isDarkMode)}
    >
      {isDarkMode ? <SunIcon className="w-5" /> : <MoonIcon className="w-5" />}
    </Button>
  );
}
