import React, { lazy, Suspense } from 'react';
import 'flowbite';
import './App.css';
import AppLayout from './common/components/Layout';
import UserContextProvider from './common/contexts/UserContext';
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from 'react-router-dom';
import AppHome from './components/Home';
import ProvidersContextProvider from './common/contexts/ProvidersContext';
import PlayListGrid from './components/Playlists/list';
import ManagePlayList from './components/Playlists/manage';
import { RequireAuth } from './common/components/RequireAuth';
import UserSettings from './components/User/Profile/settings';
import AppContextProvider from './common/contexts/AppContext';
import YoutubeDLComponent from './components/Utilities/Youtube';
import HotstarHome from './components/Ott/Hotstar';
import ContentList from './components/Ott/Contents';
import AccessSummary from './components/Admin/Reports/accesssummary';

const ProviderStatusComponent = lazy(
  () => import('./components/Admin/providerstatus')
);
// const PlayListComponent = lazy(() => import("./components/Playlists"));

function App() {
  const router = createBrowserRouter([
    {
      path: '/',
      element: <AppLayout />,
      children: [
        {
          path: 'user',
          element: <RequireAuth />,
          children: [{ path: 'settings', element: <UserSettings /> }],
        },
        {
          path: 'admin',
          element: <RequireAuth admin={true} />,
          children: [
            { path: 'providerstatus', element: <ProviderStatusComponent /> },
          ],
        },
        {
          path: 'playlist',
          element: <RequireAuth />,
          children: [
            {
              path: 'list',
              element: <PlayListGrid />,
            },
            {
              path: 'manage/:playlistid',
              element: <ManagePlayList />,
            },
          ],
        },
        {
          path: 'utilities',
          element: <RequireAuth />,
          children: [
            {
              path: 'youtube',
              element: <YoutubeDLComponent />,
            },
          ],
        },
        {
          path: 'ott',
          element: <RequireAuth />,
          children: [
            {
              path: 'hotstar',
              element: <HotstarHome />,
            },
            {
              path: 'content',
              element: <ContentList />,
            },
          ],
        },
        {
          path: 'reports',
          element: <RequireAuth admin={true} />,
          children: [
            {
              path: 'accesssummary',
              element: <AccessSummary />,
            },
          ],
        },
        { path: 'home', element: <AppHome /> },
        { path: '*', element: <Navigate to="/home" /> },
        { path: '', element: <Navigate to="/home" /> },
      ],
    },
  ]);
  return (
    <AppContextProvider>
      <UserContextProvider>
        <ProvidersContextProvider>
          <Suspense fallback={<h4>Loading...</h4>}>
            <RouterProvider router={router} />
          </Suspense>
        </ProvidersContextProvider>
      </UserContextProvider>
    </AppContextProvider>
  );
}

export default App;
