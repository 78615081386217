import {
  XCircleIcon,
  CheckCircleIcon,
  PlusCircleIcon,
  TrashIcon,
  MinusCircleIcon,
} from "@heroicons/react/24/outline";
import {
  Button,
  Card,
  Label,
  Modal,
  Select,
  Tabs,
  TextInput,
} from "flowbite-react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ReactSortable } from "react-sortablejs";
import { Link, useParams } from "react-router-dom";
import { ProvidersContext } from "../../common/contexts/ProvidersContext";
import { loadProviders } from "../../common/functions/backendCalls";
import { getData, postData, putData } from "../../common/functions/fetch";
import { ChannelDetail, PlaylistDetail } from "../../models/ProvidersModel";
import EditableLabel from "../../common/components/EditableLabel";
import { ShowConfirm, ShowMessage } from "../../common/components/Dialog";
import AnimatedContainer from "../../common/components/Layout/ainmatedcontainer";

export default function ManagePlayList() {
  const params = useParams();
  const navigate = useNavigate();
  const dataLoaded = useRef(false);
  const providersLoaded = useRef(0);
  const selectedGroupId = useRef("");
  const loadingChannel = useRef("");
  const loadedPlaylistId = useRef("");
  // const userid = useRef(sessionStorage.getItem("user") || "");
  const { providers, setProviders, channels, setChannels } =
    useContext(ProvidersContext);

  const [playlist, setPlaylist] = useState<PlaylistDetail>();
  const [selectedProvider, setSelectedProvider] = useState<string>("");
  const [selectedChannel, setSelectedChannel] = useState<string>("");
  const [showAddChannel, setShowAddChannel] = useState<boolean>(false);
  const [availableChannels, setAvailableChannels] = useState<ChannelDetail[]>(
    []
  );
  useEffect(() => {
    if (providersLoaded.current === 0 && providers.length === 0) {
      providersLoaded.current = 1;
      loadProviders(setProviders);
    } else {
      providersLoaded.current = 1;
    }
  }, [providers, setProviders]);

  useEffect(() => {
    if (
      !channels[selectedProvider] &&
      loadingChannel.current !== selectedProvider
    ) {
      loadingChannel.current = selectedProvider;
      setAvailableChannels([]);
      setSelectedChannel("");
      getData(`/providers/${selectedProvider}/getlivechannels`)
        .then((channelList: ChannelDetail[]) => {
          setChannels({ ...channels, [selectedProvider]: channelList });
          loadingChannel.current = "";
          setAvailableChannels(channels[selectedProvider]);
        })
        .catch(() => {
          ShowMessage({
            message: "An Error occurred while getting channels!!",
            continue: () => navigate("/playlist/list"),
          });
        });
    } else {
      setAvailableChannels(channels[selectedProvider]);
    }
  }, [channels, navigate, selectedProvider, setChannels]);

  useEffect(() => {
    setSelectedProvider("");
    setSelectedChannel("");
    if (params.playlistid && loadedPlaylistId.current !== params.playlistid) {
      loadedPlaylistId.current = params.playlistid;
      if (params.playlistid === "new") {
        setPlaylist({
          key: "",
          name: "New",
          channelGroups: [],
          m3uUrl: "",
        });
        dataLoaded.current = true;
      } else {
        getData(`/playlist/${params.playlistid}`)
          .then((resp: PlaylistDetail) => {
            setPlaylist(resp);
            dataLoaded.current = true;
            if (resp.channelGroups.length > 0) {
              selectedGroupId.current = resp.channelGroups[0].id;
            }
          })
          .catch(() => {
            ShowMessage({
              message: "An Error occurred while getting playlist!!",
              continue: () => navigate("/playlist/list"),
            });
          });
      }
    }
  }, [navigate, params.playlistid]);

  return (
    <AnimatedContainer showContent={dataLoaded.current}>
      <Card>
        <div className="flex flex-col sm:flex-row justify-center gap-2">
          <h5 className="text-2xl tracking-tight">
            {params.playlistid === "new" ? "Creating " : "Editing "}Playlist:
          </h5>

          {playlist && (
            <EditableLabel
              value={playlist.name}
              className="text-2xl font-bold tracking-tight"
              onBlur={(newName: string) => {
                playlist && setPlaylist({ ...playlist, name: newName });
              }}
            />
          )}
          <div className="grow hidden">
            <h5 className="mb-1 text-xl font-medium">Playlist Name:</h5>
            {playlist && (
              <TextInput
                value={playlist.name}
                onChange={(e) =>
                  playlist && setPlaylist({ ...playlist, name: e.target.value })
                }
              ></TextInput>
            )}
          </div>
          <div className="flex-none hidden">
            <div className="flex space-x-1">
              <Button
                gradientMonochrome="info"
                onClick={() => {
                  (params.playlistid === "new"
                    ? postData("/playlist/", JSON.stringify(playlist))
                    : putData(
                        "/playlist/" + params.playlistid,
                        JSON.stringify(playlist)
                      )
                  ).then(() => {
                    navigate("/playlist/list");
                  });
                }}
              >
                <CheckCircleIcon className="w-5 mr-1" />
                Save
              </Button>
              <Link to={`/playlist/list`}>
                <Button gradientMonochrome="info">
                  <XCircleIcon className="w-5 mr-1" />
                  Back
                </Button>
              </Link>
            </div>
          </div>
        </div>
        {/* eslint-disable-next-line react/style-prop-object */}
        <Tabs.Group aria-label="Channel Tabs" style={"underline"}>
          {(playlist?.channelGroups || []).map((grp) => {
            return (
              <Tabs.Item
                key={grp.id.toString() + grp.name}
                title={
                  <div className="flex gap-4">
                    <EditableLabel
                      key={"laebl" + grp.id.toString() + grp.name}
                      value={grp.name}
                      onBlur={(newGrpName: string) => {
                        if (grp.name === newGrpName || !playlist) {
                          return;
                        }
                        let chlGrp = playlist.channelGroups.find(
                          (chnlGrp) => chnlGrp.id === grp.id
                        );
                        if (chlGrp) {
                          chlGrp.name = newGrpName;
                          setPlaylist({ ...playlist });
                        }
                      }}
                    />
                    <MinusCircleIcon
                      className="w-4"
                      onClick={(e) => {
                        e.stopPropagation();
                        ShowConfirm({
                          message: `Are you sure to delete${
                            grp.channels.length > 0
                              ? ` ${grp.channels.length} channels & `
                              : ""
                          }  group: ${grp.name}?`,
                          continue: (confirm: boolean) => {
                            if (confirm) {
                              if (grp.channels.length > 0) {
                              }
                              if (confirm && playlist) {
                                setPlaylist({
                                  ...playlist,
                                  channelGroups:
                                    playlist.channelGroups.filter(
                                      (cgrp) => cgrp.id !== grp.id
                                    ) || [],
                                });
                              }
                            }
                          },
                        });
                      }}
                    />
                  </div>
                }
              >
                <ReactSortable
                  className="flex flex-wrap justify-center  items-center gap-2"
                  list={grp?.channels || []}
                  animation={200}
                  delay={2}
                  chosenClass={"sortable-chosen"}
                  dragClass={"sortable-drag"}
                  handle={".channel-card"}
                  setList={(sortedPlaylist) => {
                    if (!playlist) {
                      return;
                    }
                    let selectedGroup = playlist.channelGroups.find(
                      (plGrp) => plGrp.id === grp.id
                    );
                    if (selectedGroup) {
                      selectedGroup.channels = sortedPlaylist;
                      setPlaylist({ ...playlist });
                    }
                  }}
                >
                  {(grp?.channels || []).map((chl, ix) => (
                    <div
                      className="w-full md:w-40 channel-card"
                      key={`${ix}-${grp.id}-${chl.id}`}
                    >
                      <Card className="h-fit md:h-60">
                        <div className="flex flex-row md:flex-col">
                          <div className="flex flex-row md:flex-col gap-4 md:gap-0 items-center w-full">
                            <div className="flex-none">
                              <img
                                className="mb-0 h-10 w-10 md:mb-3 md:h-24 md:w-24 rounded-full shadow-lg"
                                src={chl.image}
                                alt={chl.name}
                              />
                            </div>
                            <div className="grow text-center">
                              <div className="flex flex-col">
                                <h5
                                  className="mb-0 md:mb-1 text-base md:text-lg md:font-medium line-clamp"
                                  title={chl.name}
                                >
                                  {chl.name}
                                </h5>
                                <div className="text-sm text-gray-500 dark:text-gray-400">
                                  {
                                    providers.find(
                                      (prv) => prv.id === chl.provider
                                    )?.name
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="flex-none">
                            <div className="flex justify-center px-4 pt-4">
                              <TrashIcon
                                className="w-4"
                                onClick={() => {
                                  ShowConfirm({
                                    message:
                                      "Are sure you want to delete: " +
                                      chl.name,
                                    continue: (confirmResult: boolean) => {
                                      if (confirmResult !== true || !playlist) {
                                        return;
                                      }
                                      let selectedGroup =
                                        playlist.channelGroups.find(
                                          (plGrp) => plGrp.id === grp.id
                                        );
                                      if (selectedGroup) {
                                        selectedGroup.channels =
                                          selectedGroup.channels.filter(
                                            (grpChnl) => grpChnl.id !== chl.id
                                          );
                                        setPlaylist({ ...playlist });
                                      }
                                    },
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </Card>
                    </div>
                  ))}
                  <div className="w-full md:w-40">
                    <Card
                      className="h-fit md:h-60"
                      onClick={() => {
                        selectedGroupId.current = grp.id;
                        setShowAddChannel(true);
                      }}
                    >
                      <div className="flex justify-center items-center">
                        <div>
                          <PlusCircleIcon className="w-8" title="Add Channel" />
                        </div>
                      </div>
                    </Card>
                  </div>
                  <Modal
                    show={showAddChannel}
                    onClose={() => setShowAddChannel(false)}
                  >
                    <Modal.Header>Add New Channel</Modal.Header>
                    <Modal.Body>
                      <div className="flex flex-col justify-items-stretch">
                        <div className=" flex-1 w-100 m-2">
                          <div className="mb-2 block">
                            <Label
                              htmlFor="providers"
                              value="Select Provider"
                            />
                          </div>
                          <Select
                            id="providers"
                            onChange={(provider) =>
                              provider.target.value &&
                              setSelectedProvider(provider.target.value)
                            }
                          >
                            {providers &&
                              providers.map((provider, prvix) => (
                                <option
                                  key={"prvSelect" + prvix}
                                  value={provider.id}
                                >
                                  {provider.name}
                                </option>
                              ))}
                          </Select>
                        </div>
                        <div className=" flex-1 w-100  m-2">
                          <div className="mb-2 block">
                            <Label htmlFor="providers" value="Select Channel" />
                          </div>
                          <Select
                            id="channels"
                            onChange={(channel) => {
                              const chl = (availableChannels || []).find(
                                (chl) =>
                                  chl.id &&
                                  channel.target.value &&
                                  chl.id.toString() ===
                                    channel.target.value.toString()
                              );
                              if (channel.target.value && chl) {
                                setSelectedChannel(chl.id);
                              }
                            }}
                          >
                            {availableChannels &&
                              availableChannels.map((channel, chnlIx) => (
                                <option
                                  key={"chnlSelect" + chnlIx}
                                  value={channel.id}
                                >
                                  {channel.name}
                                </option>
                              ))}
                          </Select>
                        </div>
                      </div>
                    </Modal.Body>
                    <Modal.Footer className="justify-end">
                      <Button
                        onClick={() => {
                          if (!playlist) {
                            return;
                          }
                          let selectedGroup = playlist.channelGroups.find(
                            (plGrp) => plGrp.id === selectedGroupId.current
                          );
                          if (selectedGroup) {
                            let selChnl = availableChannels.find(
                              (achnl) => achnl.id === selectedChannel
                            );
                            if (selChnl) {
                              selectedGroup.channels.push({
                                ...selChnl,
                                group_name: selectedGroup.name,
                                provider: selChnl.provider || selectedProvider,
                              });
                              setPlaylist({ ...playlist });
                            }
                          }
                          setShowAddChannel(false);
                        }}
                      >
                        Add
                      </Button>
                      <Button
                        color="gray"
                        className="transition ease-in-out delay-150 hover:scale-110 duration-300"
                        onClick={() => setShowAddChannel(false)}
                      >
                        Cancel
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </ReactSortable>
              </Tabs.Item>
            );
          })}
          <Tabs.Item
            disabled
            title={
              <div
                className="flex"
                onClick={() => {
                  if (!playlist) {
                    return;
                  }
                  playlist.channelGroups = [
                    ...playlist.channelGroups,
                    {
                      id: new Date().getTime().toString(),
                      name: "New Group " + playlist.channelGroups.length,
                      channels: [],
                    },
                  ];
                  setPlaylist({ ...playlist });
                  // setSelectedTab(Object.keys(channelGroups).length);
                }}
              >
                <PlusCircleIcon className="w-5"></PlusCircleIcon> New Group
              </div>
            }
          ></Tabs.Item>
        </Tabs.Group>
        <div className="flex justify-center gap-2">
          <Button
            gradientMonochrome="info"
            onClick={() => {
              (params.playlistid === "new"
                ? postData("/playlist/", JSON.stringify(playlist))
                : putData(
                    "/playlist/" + params.playlistid,
                    JSON.stringify(playlist)
                  )
              ).then(() => {
                navigate("/playlist/list");
              });
            }}
          >
            <CheckCircleIcon className="w-5 mr-1" />
            Save
          </Button>
          <Link to={`/playlist/list`}>
            <Button gradientMonochrome="info">
              <XCircleIcon className="w-5 mr-1" />
              Back
            </Button>
          </Link>
        </div>
      </Card>
    </AnimatedContainer>
  );
}
