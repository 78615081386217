import React, { Component, useContext, useEffect, useState } from "react";
import { Card } from "flowbite-react";
import { ProvidersContext } from "../../common/contexts/ProvidersContext";
import { loadProviders } from "../../common/functions/backendCalls";
import CenterContainer from "../../common/components/Layout/centercontainer";
import AnimatedContainer from "../../common/components/Layout/ainmatedcontainer";

const config = (window as any)._env;

export class AppHome1 extends Component<any, any> {
  componentDidMount(): void {
    console.log(this.props.ProvidersContext?.setProviders);
  }
  render() {
    return (
      <div>
        <Card className="text-center">
          <h5 className="text-2xl font-bold tracking-tight ">
            Welcome to 1 {config.appTitle}
          </h5>
          <p className="font-normal text-gray-700 dark:text-gray-400 w-100 justify-items-center">
            <div className="flex flex-wrap space-x-2 justify-center">
              {this.props.ProvidersContext?.providers.map(
                (provider: any, ix: number) => {
                  return (
                    <img
                      key={"prv" + ix}
                      className="object-scale-down h-12 w-12"
                      src={provider.image}
                      alt={provider.name}
                    />
                  );
                }
              )}
            </div>
          </p>
        </Card>
      </div>
    );
  }
}

export default function AppHome() {
  const [dataLoaded, setDataLoaded] = useState(false);
  const { providers, setProviders } = useContext(ProvidersContext);
  useEffect(() => {
    if (dataLoaded === false) {
      if (providers.length === 0) {
        loadProviders(setProviders);
      }
      setDataLoaded(true);
    }
  }, [dataLoaded, providers.length, setProviders]);
  return (
    <CenterContainer>
      <Card className="text-center h-72">
        <AnimatedContainer showContent={dataLoaded === true}>
          <div className="flex flex-col gap-16">
            <div className="text-2xl font-bold tracking-tight ">
              Welcome to {config.appTitle}
            </div>
            <div className="font-normal text-gray-700 dark:text-gray-400 w-100 justify-items-center">
              <div className="flex flex-wrap space-x-2 justify-center">
                {providers.map((provider, ix) => {
                  return (
                    <img
                      key={"prv" + ix}
                      className="object-scale-down h-12 w-12"
                      src={provider.image}
                      alt={provider.name}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </AnimatedContainer>
      </Card>
    </CenterContainer>
  );
}
