import React from 'react';
// import VideoJS from '../../../common/components/Videoplayer';

// var videojs: any;
// const config = (window as any)._env;

export default function HotstarHome() {
  // const user = sessionStorage.getItem('user') || 'anonymous';
  // const playerRef = React.useRef(null);

  // const videoJsOptions = {
  //   autoplay: true,
  //   controls: true,
  //   responsive: true,
  //   fluid: true,
  //   sources: [
  //     {
  //       src: `${config.apiBase}/${user}/hotstar/content/1100093329.mpd?retry=true`,
  //       type: 'application/dash+xml',
  //     },
  //   ],
  // };

  // const handlePlayerReady = (player: any) => {
  //   playerRef.current = player;

  //   // You can handle player events here, for example:
  //   player.on('waiting', () => {
  //     videojs.log('player is waiting');
  //   });

  //   player.on('dispose', () => {
  //     videojs.log('player will dispose');
  //   });
  // };

  return (
    <>
      Work in progress
      {/* <VideoJS options={videoJsOptions} onReady={handlePlayerReady} /> */}
    </>
  );
}
