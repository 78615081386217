import { Button, Modal } from "flowbite-react";
import React, { useState } from "react";
import DialogWrapper from "./wrapper";

export interface MessageProps {
  continue?: Function;
  message: string;
  okText?: string;
  title?: string;
}

export const ShowMessage = (messageProps: MessageProps) => {
  return DialogWrapper(ShowMessageComponent, "app-message")(messageProps);
};

function ShowMessageComponent(props: MessageProps) {
  const [show, setShow] = useState(true);

  return (
    <Modal
      show={show}
      size="sm"
      onClose={() => {
        props.continue && props.continue(true);
        setShow(false);
      }}
    >
      {props.title && <Modal.Header>{props.title}</Modal.Header>}
      <Modal.Body>
        <div className="">
          <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
            {props.message}
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            props.continue && props.continue(true);
            setShow(false);
          }}
        >
          {props.okText || "Ok"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
export interface ConfirmProps {
  continue: Function;
  message: string;
  noText?: string;
  subtitle?: string;
  title?: string;
  yesText?: string;
}

export const ShowConfirm = (confirmProps: ConfirmProps) => {
  return DialogWrapper(ShowConfirmComponent, "app-confirm")(confirmProps);
};

function ShowConfirmComponent(props: ConfirmProps) {
  const [show, setShow] = useState(true);

  return (
    <Modal
      show={show}
      size="sm"
      onClose={() => {
        props.continue && props.continue(false);
        setShow(false);
      }}
    >
      {props.title && <Modal.Header>{props.title}</Modal.Header>}
      <Modal.Body>
        <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
          {props.message}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            props.continue && props.continue(true);
            setShow(false);
          }}
        >
          {props.yesText || "Yes"}
        </Button>
        <Button
          onClick={() => {
            props.continue && props.continue(false);
            setShow(false);
          }}
        >
          {props.noText || "No"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
