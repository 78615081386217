import { Card } from "flowbite-react";
import React, { useContext } from "react";
import { UserContext } from "../../contexts/UserContext";

interface IAnimatedContinedProps {
  showContent: boolean;
  children: JSX.Element;
}

export default function AnimatedContainer(props: IAnimatedContinedProps) {
  const { user } = useContext(UserContext);
  return (
    <>
      <div
        className={
          "transform transition-all duration-75 ease-in  " +
          (props.showContent === true ? "scale-100" : "scale-0")
        }
      >
        {props.children}
      </div>
      {props.showContent === false && (
        <Card className="w-full h-72 animate-pulse">
          <div className="flex items-center justify-center">
            Hang in {user.name || " awesome user"}. We will be there
            soon!!!
          </div>
        </Card>
      )}
    </>
  );
}
