import React, { useContext } from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { UserContext } from "../../contexts/UserContext";

export function RequireAuth({ admin }: { admin?: boolean }) {
  let { user } = useContext(UserContext);
  let location = useLocation();

  if (!user.isLoggedIn) {
    return <div>Authentication is in progress. Please wait!!!</div>
  }
  else if (!user || !user.isActive || (admin && !user.isAdmin)) {
    return <Navigate to="/home" state={{ from: location }} replace />;
  } else {
    return <Outlet />;
  }
}
