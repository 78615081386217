var setIsLoading: any;
var httpCallsInProgress = 0;
const config = (window as any)._env;

export function setupFetch(setIsLoadingFunction: any) {
  setIsLoading = setIsLoadingFunction;
}

function incrementHttpCalls() {
  httpCallsInProgress++;
  toggleLoading();
}
function decrementHttpCalls() {
  httpCallsInProgress--;
  toggleLoading();
}
function resetHttpCalls() {
  httpCallsInProgress = 0;
  toggleLoading();
}

function toggleLoading() {
  if (typeof setIsLoading === "function") {
    setIsLoading(httpCallsInProgress > 0);
  }
}

function handleErrors(response: any) {
  if (!response.ok) {
    resetHttpCalls();
    if (response.status === 401) {
      sessionStorage.clear();
      window.location.reload();
      return null;
    }
    throw response.statusText;
  }
  return response;
}
function getBaseUrl(url: string) {
  const user = sessionStorage.getItem("user") || "anonymous";
  return `${config.apiBase}/${user}${url}`;
}

function getToken() {
  const token = sessionStorage.getItem("token") || "";
  return token;
}

export function deleteData(url: string, body?: any) {
  return fetchBase("DELETE", url, body);
}

export function getData(url: string) {
  return fetchBase("GET", url);
}

export function postData(url: string, body?: any) {
  return fetchBase("POST", url, body);
}

export function putData(url: string, body?: any) {
  return fetchBase("PUT", url, body);
}

export function getStream(url: string) {
  return fetchStream("GET", url);
}

export function fetchBase(method: string, url: string, body?: any) {
  incrementHttpCalls();
  return fetch(getBaseUrl(url), {
    method,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-token": getToken()!,
    },
    body,
  })
    .then(handleErrors)
    .then((response) => {
      decrementHttpCalls();
      if (response.status !== 200) {
        console.error(response);
        throw new Error("An error occurred");
      }
      return response.json();
    })
    .catch((err) => {
      resetHttpCalls();
      console.error(err);
    });
}

export function fetchStream(method: string, url: string, body?: any) {
  return fetch(getBaseUrl(url), {
    method,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-token": getToken()!,
    },
    body,
  });
}
