import { TextInput } from "flowbite-react";
import React, { useState } from "react";

export interface EditableLabelProps {
  value: string;
  onBlur?: any;
  className?: string;
}

export default function EditableLabel(props: EditableLabelProps) {
  const [editing, setEditing] = useState(false);
  const [value, setValue] = useState(props.value);
  return (
    <div>
      {!editing && (
        <div onDoubleClick={() => setEditing(true)} {...props}>
          {value}
        </div>
      )}
      {editing && (
        <TextInput
          value={value}
          onChange={(e) => setValue(e.target.value)}
          onBlur={() => {
            setEditing(false);
            value && props.onBlur && props.onBlur(value || props.value);
            if (!value) {
              setValue(props.value);
            }
          }}
        ></TextInput>
      )}
    </div>
  );
}
