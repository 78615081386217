import React, { createContext, useState } from "react";
import { UserDetail } from "../../models/UserDetail";
export const defaultUser: UserDetail = {
  isActive: false,
  isAdmin: false,
  isLoggedIn: false,
  email: "",
  picture: "",
  name: "",
  userid: "",
};
export const UserContext = createContext({
  user: defaultUser,
  setUser: (user: UserDetail) => {},
});

const UserContextProvider = (props: any) => {
  const [user, setUser] = useState({ ...defaultUser });
  return (
    <UserContext.Provider value={{ user, setUser }}>
      {props.children}
    </UserContext.Provider>
  );
};
export default UserContextProvider;
