import React from "react";
import "./index.css";

export default function IndeterminateProgress() {
  return (
    <div className="relative -mt-1.5 w-full">
      <div className="bg-gray-100 dark:bg-gray-600 h-1.5 w-full overflow-hidden">
        <div className="w-5/6 h-full rounded-2xl bg-blue-700 progress-bar-value"></div>
      </div>
    </div>
  );
}
