import { Button, Card } from "flowbite-react";
import React, { useContext } from "react";
import { ShowMessage } from "../../../common/components/Dialog";
import CenterContainer from "../../../common/components/Layout/centercontainer";
import { UserContext } from "../../../common/contexts/UserContext";
import { getData } from "../../../common/functions/fetch";
import { UserDetail } from "../../../models/UserDetail";

export default function UserSettings() {
  const { user, setUser } = useContext(UserContext);

  return (
    <CenterContainer>
      <Card>
        <div className="flex  justify-center">
          <h3 className="text-xl font-bold">Your Profile</h3>
        </div>
        <div>
          <div className="flex flex-col gap-6">
            <div className="flex flex-col md:flex-row">
              <div className="flex-none w-52">Name</div>
              <div className="grow">{user.name}</div>
            </div>
            <div className="flex flex-col md:flex-row">
              <div className="flex-none w-52">Email</div>
              <div className="grow">{user.email}</div>
            </div>
            <div className="flex flex-col md:flex-row">
              <div className="flex-none w-52">Registered IP</div>
              <div className="grow">{user.registeredIp}</div>
            </div>
            <div className="flex flex-col md:flex-row">
              <div className="flex-none w-52">Current IP</div>
              <div className="grow">{user.currentIp}</div>
            </div>
          </div>
          {user.currentIp !== user.registeredIp && (
            <div className="flex justify-center">
              <Button gradientMonochrome={"info"} onClick={updateIp}>
                Update IP
              </Button>
            </div>
          )}
        </div>
      </Card>
    </CenterContainer>
  );

  function updateIp(): any {
    getData(`/user/updateip`).then((response: any) => {
      setUserFromResponse(response);
      ShowMessage({
        message: "Updated IP Successfully!!!",
        title: "Success",
      });
    });
  }

  function setUserFromResponse(response: any) {
    const userData = response as UserDetail;
    userData.isLoggedIn = true;
    setUser(userData);
  }
}
