import { Label, TextInput } from 'flowbite-react';
import { useEffect, useRef, useState } from 'react';
import { HiSearch } from 'react-icons/hi';
import { getData } from '../../../common/functions/fetch';
// import AnimatedContainer from '../../../common/components/Layout/ainmatedcontainer';
import './index.css';
import { Edge, JustWatchSearchResult } from '../../../models/JustWatchModel';
import { JioSearchResult } from '../../../models/JioSearchResult';
import { JioMetadataResult } from '../../../models/JioMetadataResult';
import { ShowConfirm } from '../../../common/components/Dialog';
import { TrashIcon, ArrowPathIcon } from '@heroicons/react/24/outline';

export interface Suggestion {
  contentId: string;
  provider?: string;
  description?: string;
  name: string;
  thumbnail: string;
  subtitle: string;
  contentType: string;
  metadataUrl: string;
}

export interface Content {
  contentId: string;
  description?: string;
  provider?: string;
  name: string;
  thumbnail: string;
  subtitle: string;
  contentType: string;
}
export default function ContentList() {
  // const dataLoaded = useRef(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);
  const [contents, setContents] = useState<Content[]>([]);
  const [showSuggestion, setShowSuggestion] = useState<boolean>(false);
  const searchBoxRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        searchBoxRef.current &&
        !searchBoxRef.current.contains(event.target as Node)
      ) {
        // Clicked outside the search box and suggestions, so clear the suggestions
        if (!searchQuery || searchQuery === '') {
          setSuggestions([]);
        }
        setShowSuggestion(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [searchQuery]);
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (!searchQuery || searchQuery === '') {
        setSuggestions([]);
        return;
      } else setShowSuggestion(true);
      Promise.all([
        getData(`/jiotvplus/search?filter=${searchQuery}`),
        getData(`/justwatch/search?filter=${searchQuery}`),
      ])
        .then(
          ([jioSearchResult, justWatchSearchResult]: [
            JioSearchResult,
            JustWatchSearchResult
          ]) => {
            const suggestions: Suggestion[] = [];
            console.log(jioSearchResult);
            if (jioSearchResult?.data) {
              jioSearchResult?.data[0].items.forEach((content) => {
                suggestions.push({
                  contentId: content.contentId,
                  thumbnail: content.thumbnail,
                  name: content.name,
                  subtitle: content.subtitle,
                  description: content.description,
                  contentType: content.contentType,
                  provider: content.provider,
                  metadataUrl: `/jiotvplus/metadata/${content.contentType}/${content.contentId}`,
                });
              });
            }
            (justWatchSearchResult?.data?.popularTitles?.edges || []).forEach(
              (edge: Edge) => {
                const { id, __typename, content } = edge.node;
                suggestions.push({
                  contentId: id,
                  thumbnail:
                    'https://images.justwatch.com' +
                    content.posterUrl
                      .replace('{profile}', 's166')
                      .replace('{format}', 'webp'),
                  name: content.title,
                  subtitle: `${__typename}, ${content.originalReleaseYear}`,
                  contentType: content.__typename,
                  provider: content.title,
                  metadataUrl: 'justwatch',
                });
              }
            );
            setSuggestions(suggestions);
          }
        )
        .catch((error) => console.error('Error fetching suggestions:', error));
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchQuery]);

  const handleInputChange = (event: any) => {
    const query = event.target.value;
    setSearchQuery(query);
  };
  return (
    <div>
      <div className="search-box-container" ref={searchBoxRef}>
        <div className="w-100 mb-4">
          <div className="mb-2 block">
            <Label htmlFor="search" value="Search Content" />
          </div>
          <TextInput
            id="search"
            placeholder="Type here to search"
            required
            rightIcon={HiSearch}
            value={searchQuery}
            onChange={handleInputChange}
            onFocus={() => {
              if (suggestions.length > 0) {
                setShowSuggestion(true);
              }
            }}
            type="text"
          />
        </div>
        {showSuggestion && suggestions.length > 0 && (
          <div className="z-999">
            {suggestions.length > 0 && (
              <div className="absolute top-full w-full max-h-40 overflow-y-auto bg-white dark:border-gray-700 dark:bg-gray-800 bg-opacity-100 border border-t-0 rounded-b-lg shadow">
                {suggestions
                  .filter(
                    (s) => !contents.some((c) => c.contentId === s.contentId)
                  )
                  .map((suggestion) => (
                    <div
                      key={suggestion.name}
                      className="flex items-center p-2 border-b border-gray-300"
                      onClick={() => {
                        getData(suggestion.metadataUrl)
                          .then((metadataResult: JioMetadataResult) => {
                            // const contents: Content[] = [];
                            console.log(metadataResult);
                            setSearchQuery('');
                            setSuggestions([]);
                            if ((metadataResult.filter || []).length === 1) {
                              // const content: Content = {
                              // }
                            }
                            // if (metadataResult?.data) {
                            //   metadataResult?.data[0].items.forEach((content) => {
                            //     suggestions.push({
                            //       contentId: content.contentId,
                            //       thumbnail: content.thumbnail,
                            //       name: content.name,
                            //       subtitle: content.subtitle,
                            //       contentType: content.contentType,
                            //       provider: content.provider,
                            //     });
                            //   });
                            // }
                            console.log(contents);
                            setContents([...contents, suggestion]);
                          })
                          .catch((error) =>
                            console.error('Error fetching content:', error)
                          );
                      }}
                    >
                      <div>
                        <img
                          src={suggestion.thumbnail}
                          alt={suggestion.name}
                          className="w-28 h-28 object-cover mr-3"
                        />
                      </div>

                      <div className="ml-4">
                        <span className="capitalize block text-gray-900  dark:text-white">
                          {suggestion.name}
                        </span>
                        <span className="text-sm block text-gray-600  dark:text-gray-200">
                          {suggestion.subtitle}
                        </span>
                      </div>
                    </div>
                  ))}
              </div>
            )}
          </div>
        )}
      </div>
      <div className="flex justify-center">
        <h3 className="text-xl font-bold  ">Your Contents</h3>
      </div>
      <div className="flex  flex-row flex-wrap justify-center md:justify-start -mx-4">
        {contents.map((content) => {
          return (
            <div className="max-w-xs m-2 rounded-lg overflow-hidden shadow-lg bg-white dark:border-gray-700 dark:bg-gray-800">
              <img
                src={content.thumbnail}
                alt={content.name}
                className="w-full h-24 md:h-56  object-cover"
              />
              <div className="p-2 mb:p-4">
                <h2 className="text-base md:text-xl text-gray-900  dark:text-white font-semibold mb-1 md:mb-2">
                  {content.name}
                </h2>
                <p className="text-gray-500  dark:text-gray-400 text-xs md:text-sm">
                  {content.provider} | {content.subtitle}
                </p>
              </div>
              <div className="flex-none">
                <div className="flex justify-evenly p-2 mb:p-4">
                  <TrashIcon
                    className="w-4"
                    onClick={() => {
                      ShowConfirm({
                        message: 'Are sure you want to delete: ' + content.name,
                        continue: (confirmResult: boolean) => {
                          if (confirmResult !== true || !content) {
                            return;
                          }
                          let remainingContents = contents.filter(
                            (c) => c.contentId !== content.contentId
                          );
                          setContents([...remainingContents]);
                        },
                      });
                    }}
                  />
                  <ArrowPathIcon
                    className="w-4"
                    onClick={() => {
                      ShowConfirm({
                        message: 'Are sure you want to delete: ' + content.name,
                        continue: (confirmResult: boolean) => {
                          if (confirmResult !== true || !content) {
                            return;
                          }
                          let remainingContents = contents.filter(
                            (c) => c.contentId !== content.contentId
                          );
                          setContents([...remainingContents]);
                        },
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
      {/* <AnimatedContainer showContent={dataLoaded.current === 1}>
      </AnimatedContainer> */}
    </div>
  );
}
