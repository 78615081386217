import { useEffect, useState } from 'react';
import {
  BarChart,
  Bar,
  CartesianGrid,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { getData } from '../../../common/functions/fetch';
import moment from 'moment';

export default function AccessSummary() {
  const [data, setData] = useState([]);
  useEffect(() => {
    let dataArray: any = [];
    const todayDate = moment().format('YYYY-MM-DD');
    getData(
      `/redis/access-summary/?start_time=${todayDate}T00:00:00&end_time=${todayDate}T23:59:59`
    ).then((resp: any) => {
      Object.keys(resp).forEach((key) => {
        const { user_name, total_requests } = resp[key];
        dataArray.push({
          name: user_name,
          total_requests,
        });
      });
      setData(dataArray);
    });
  }, []);
  return (
    <BarChart width={700} height={350} data={data}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Bar dataKey="total_requests" fill="#8884d8" name="Total Requests" />
    </BarChart>
  );
}
