import React, {
  createContext,
  useEffect,
  useRef,
  useState,
  useCallback,
} from "react";
import { setupFetch } from "../functions/fetch";
export const AppContext = createContext({
  isLoadingData: false,
  setIsLoading: (isLoading: boolean) => {},
  isDarkMode: true,
  setIsDarkMode: (isDarkMode: boolean) => {},
});

const addBodyClass = (className: string) =>
  document.body.classList.add(className);
const removeBodyClass = (className: string) =>
  document.body.classList.remove(className);

function updateBodyClass(darkMode: boolean) {
  if (darkMode) {
    addBodyClass("dark");
  } else {
    removeBodyClass("dark");
  }
}
const AppContextProvider = (props: any) => {
  let setupFetchDone = useRef(false);
  const [isLoadingData, setIsLoading] = useState(false);
  const [isDarkMode, setIsDarkModeState] = useState(
    (localStorage.getItem("darkmode") || "1") === "1"
  );
  useEffect(() => {
    if (setupFetchDone.current) {
      return;
    }
    setupFetchDone.current = true;
    updateBodyClass((localStorage.getItem("darkmode") || "1") === "1");
    setupFetch(setIsLoading);
  }, [setIsLoading]);
  const setIsDarkMode = useCallback(
    (darkMode: boolean) => {
      localStorage.setItem("darkmode", darkMode ? "1" : "0");
      updateBodyClass(darkMode);
      setIsDarkModeState(darkMode);
    },
    [setIsDarkModeState]
  );

  return (
    <AppContext.Provider
      value={{ isLoadingData, setIsLoading, isDarkMode, setIsDarkMode }}
    >
      {props.children}
    </AppContext.Provider>
  );
};
export default AppContextProvider;
