import React, { useContext, useEffect, useState, ReactElement } from 'react';
import { Dropdown, Avatar } from 'flowbite-react';
import { gapi } from 'gapi-script';
import { UserDetail } from '../../../models/UserDetail';
import { defaultUser, UserContext } from '../../../common/contexts/UserContext';
import { Button } from 'flowbite-react';
import { getData } from '../../../common/functions/fetch';
import { Link, useNavigate } from 'react-router-dom';
import DarkModeToggleButton from '../../../common/components/Layout/darkmodetogglebutton';

const config = (window as any)._env;
let done = false;
const initClient = (options: {
  updateLoggedInStatus: (status: boolean) => void;
}) => {
  if (done) {
    return;
  }
  done = true;
  gapi.client
    .init({
      clientId: config.google_client_id,
      scope: 'email',
    })
    .then(() => {
      // Listen for sign-in state changes.

      gapi.auth2
        .getAuthInstance()
        .isSignedIn.listen(options.updateLoggedInStatus);

      // Handle the initial sign-in state.
      options.updateLoggedInStatus(
        gapi.auth2.getAuthInstance().isSignedIn.get()
      );
    })
    .catch((err: any) => {
      console.error('Caught error', err);
    });
};
function UserProfileButton(options: {
  user: UserDetail;
  logIn: () => void;
  logOut: () => void;
}): ReactElement {
  const { logIn, logOut, user } = options;
  return user?.isLoggedIn === true ? (
    <Dropdown
      arrowIcon={false}
      inline={true}
      label={<Avatar img={user.picture} rounded={true} />}
    >
      <Dropdown.Header>
        <span className="block text-sm">{user.name}!</span>
        <span className="block truncate text-sm font-medium">{user.email}</span>
      </Dropdown.Header>
      <Link to="/user/settings">
        <Dropdown.Item>Settings</Dropdown.Item>
      </Link>
      {user.isAdmin && (
        <>
          <Link to="/admin/providerstatus">
            <Dropdown.Item>Provider Status</Dropdown.Item>
          </Link>
        </>
      )}
      <Dropdown.Divider />
      <Dropdown.Item>
        <div className="flex w-full">
          <div className="flex-none">
            <Button onClick={logOut} gradientMonochrome={'info'}>
              Sign out
            </Button>
          </div>
          <div className="grow" />
          <div className="flex-none">
            <DarkModeToggleButton />
          </div>
        </div>
      </Dropdown.Item>
    </Dropdown>
  ) : (
    <Button onClick={logIn} gradientMonochrome={'info'}>
      Sign in
    </Button>
  );
}

export default function UserProfile() {
  const navigate = useNavigate();
  const [initiatedClient, setInitiatedClient] = useState<boolean>(false);
  const { user, setUser } = useContext(UserContext);

  useEffect(() => {
    gapi.load('client:auth2', () =>
      initClient({
        updateLoggedInStatus: (status) => {
          const auth = gapi.auth2.getAuthInstance();
          if (status) {
            const authUser = auth.currentUser.get().getBasicProfile();
            const authToken = auth.currentUser.get().getAuthResponse().id_token;
            sessionStorage.setItem('user', authUser.getId());
            sessionStorage.setItem('token', authToken);
            getData(`/user/getdetails`).then((response: any) => {
              const dbUser = response as UserDetail;
              dbUser.isLoggedIn = true;
              setUser(dbUser);
            });
          } else {
            navigate('/home');
          }
        },
      })
    );

    setInitiatedClient(true);
  }, [initiatedClient, navigate, setUser]);

  return (
    <div>
      <div>
        <UserProfileButton
          user={user}
          logIn={() => gapi.auth2.getAuthInstance().signIn()}
          logOut={() => {
            localStorage.clear();
            sessionStorage.clear();
            gapi.auth2.getAuthInstance().signOut();
            setUser({ ...defaultUser });
          }}
        />
      </div>
    </div>
  );
}
