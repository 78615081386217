import React from "react";
import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";

const _ID_ = "DialogWrapper";
function removeNode(selector: string) {
  let renderedDiv = document.getElementById(_ID_);
  if (renderedDiv) {
    ReactDOM.unmountComponentAtNode(renderedDiv);
    getContainer(selector).removeChild(renderedDiv);
  }
}
function getContainer(selector: string) {
  return (
    document.querySelector(selector) ||
    document.getElementById("root") ||
    document.body
  );
}

export default function DialogWrapper(component: any, selector: string) {
  return function (props = {}) {
    removeNode(selector);
    const div = document.createElement("div");
    div.id = _ID_;
    getContainer(selector).insertBefore(
      div,
      getContainer(selector).childNodes[0]
    );
    const element = React.createElement(
      component,
      Object.assign({}, props, {})
    );
    createRoot(div).render(element);
  };
}
