import { Button, Card, Label, TextInput } from 'flowbite-react';
import React, { useState } from 'react';
// import CenterContainer from '../../../common/components/Layout/centercontainer';
import { getData } from '../../../common/functions/fetch';

function YoutubeDLComponent() {
  const [searchStr, setSearchStr] = useState('');
  const [fileId, setFileId] = useState('');
  const [results, setResults] = useState([]);
  return (
    <>
      <Card className="text-center h-72">
        <div className="flex justify-center">
          <h3 className="text-xl font-bold ">Download Youtube videos</h3>
        </div>

        <form className="flex flex-col gap-4">
          <div>
            <div className="mb-2 block text-left">
              <Label htmlFor="url" value="Youtube Url" />
            </div>
            <TextInput
              id="url"
              type="text"
              placeholder="Enter youtube url"
              value={searchStr}
              onChange={(e) => setSearchStr(e.target.value)}
              required={true}
              disabled={fileId !== ''}
            />
          </div>
          {(fileId && (
            <Button onClick={() => (window.location.href = '')}>
              Download MP3
            </Button>
          )) || (
            <Button type="button" onClick={processInput}>
              Submit
            </Button>
          )}
        </form>
      </Card>
      <div className="flex flex-row gap-4 w-full overflow-x-scroll h-[500px]">
        {results.map((result: any) => (
          <div className="text-center">
            <Card
              imgSrc={result.album?.images[1]?.url}
              className="w-[250px] h-[250px]"
            >
              <h5 className="text-xl font-bold tracking-tight">
                {result.name}
              </h5>
              <p className="font-thin">{result.album?.name}</p>
            </Card>
          </div>
        ))}
      </div>
    </>
  );

  function processInput() {
    if (searchStr.startsWith('http')) {
      downloadYoutubeVideo();
    } else {
      searchVideos();
    }
  }

  function searchVideos() {
    console.log('Searching');
    getData('/spotify/search?searchStr=' + searchStr).then((resp: any) => {
      setResults(resp.tracks?.tracks?.items || []);
    });
  }

  function downloadYoutubeVideo() {
    console.log(searchStr);
    getData('/youtube/download?url=' + searchStr).then((resp: any) => {
      console.log(resp);
      if (resp.status === 'Success') {
        setFileId(resp.fileId);
      }
    });
  }
}

export default YoutubeDLComponent;
