import { Button, Table } from "flowbite-react";
import {
  ClipboardDocumentIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import React, { useEffect, useRef, useState } from "react";
import { deleteData, getData } from "../../common/functions/fetch";
import { PlaylistDetail } from "../../models/ProvidersModel";
import { Link } from "react-router-dom";
import { ShowConfirm } from "../../common/components/Dialog";
import AnimatedContainer from "../../common/components/Layout/ainmatedcontainer";

export default function PlayListGrid() {
  const dataLoaded = useRef(0);
  const userid = useRef(sessionStorage.getItem("user") || "");
  const [playlists, setPlayLists] = useState<PlaylistDetail[]>([]);
  useEffect(() => {
    if (dataLoaded.current === 0) {
      dataLoaded.current = 1;
      getData("/playlist/").then((userPlayLists: PlaylistDetail[]) =>
        setPlayLists(userPlayLists)
      );
    }
  }, []);
  return (
    playlists && (
      <div>
        <div className="flex justify-center">
          <h3 className="text-xl font-bold  ">Your Playlists</h3>
        </div>
        <AnimatedContainer showContent={dataLoaded.current === 1}>
          <Table hoverable={true}>
            <Table.Head>
              <Table.HeadCell>Playlist Name</Table.HeadCell>
              <Table.HeadCell>
                <div className="flex flex-col md:flex-row items-start md:items-center">
                  <div className="grow">M3U Url</div>
                  <div className="visible md:hidden">{createButton()}</div>
                </div>
              </Table.HeadCell>
              <span className="hidden md:inline">
                <Table.HeadCell className="flex justify-end">
                  {createButton()}
                </Table.HeadCell>
              </span>
            </Table.Head>
            <Table.Body className="divide-y">
              {(playlists || []).map((pl, ix) => (
                <Table.Row
                  key={"pl" + ix}
                  className="bg-white dark:border-gray-700 dark:bg-gray-800"
                >
                  <Table.Cell className="whitespace-nowrap font-medium ">
                    {pl.name}
                  </Table.Cell>
                  <Table.Cell>
                    <div className="flex flex-col break-all">
                      {pl.m3uUrl}
                      <span className="visible md:hidden">
                        {getActionButtons(pl)}
                      </span>
                    </div>
                  </Table.Cell>
                  <p className="hidden md:inline">
                    <Table.Cell className="flex justify-end">
                      {getActionButtons(pl)}
                    </Table.Cell>
                  </p>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </AnimatedContainer>
      </div>
    )
  );

  function createButton() {
    return (
      <Link
        className="btn btn-outline-secondary"
        to={`/playlist/manage/new`}
        id="new-m3u"
      >
        <Button gradientMonochrome={"info"}>New Playlist</Button>
      </Link>
    );
  }

  function getActionButtons(pl: PlaylistDetail) {
    return (
      <div className="flex gap-2">
        <Button
          gradientMonochrome="info"
          onClick={() => navigator.clipboard.writeText(pl.m3uUrl)}
        >
          <ClipboardDocumentIcon className="w-6" />
        </Button>
        {pl.createdby?.userid === userid.current && (
          <>
            <Button gradientMonochrome="info">
              <Link
                className="btn btn-outline-secondary"
                to={`/playlist/manage/${pl.key}`}
                id="manage-m3u"
              >
                <PencilIcon className="w-6" />
              </Link>
            </Button>
            <Button
              gradientMonochrome="info"
              onClick={() => {
                ShowConfirm({
                  message: "Are you sure you want to delete: " + pl.name,
                  title: "Confirm Delete",
                  continue: (confirmResult: boolean) => {
                    if (confirmResult === true) {
                      deleteData(`/playlist/${pl.key}`).then((resp) => {
                        setPlayLists(resp);
                      });
                    }
                  },
                });
              }}
            >
              <TrashIcon className="w-6" />
            </Button>
          </>
        )}
      </div>
    );
  }
}
