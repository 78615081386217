import React from "react";

export default function CenterContainer(props: any) {
  return (
    <div className="flex flex-col h-full p-0 m-0 items-center justify-center">
      <div className="grow max-h-56"></div>
      <div className="w-full max-w-4xl">{props.children}</div>
      <div className="grow"></div>
    </div>
  );
}
