import React, { createContext, useState } from "react";
import { ChannelDetail, ChannelProvider } from "../../models/ProvidersModel";
// export const channels: ChannelDetail[] = [];
// export const providers: ChannelProvider[] = [];
export const ProvidersContext = createContext<{
  channels: { [providerName: string]: ChannelDetail[] };
  setChannels: (channels: { [providerName: string]: ChannelDetail[] }) => void;
  providers: ChannelProvider[];
  setProviders: (providers: ChannelProvider[]) => void;
}>({
  channels: {},
  setChannels: (channels: { [providerName: string]: ChannelDetail[] }) => {},
  providers: [],
  setProviders: (providers: ChannelProvider[]) => {},
});

const ProvidersContextProvider = (props: any) => {
  const [channels, setChannels] = useState<{
    [providerName: string]: ChannelDetail[];
  }>({});
  const [providers, setProviders] = useState<ChannelProvider[]>([]);
  return (
    <ProvidersContext.Provider
      value={{ channels, setChannels, providers, setProviders }}
    >
      {props.children}
    </ProvidersContext.Provider>
  );
};
export default ProvidersContextProvider;
